import {
  getPage,
  getSiteConfig,
  getTranslations,
} from '@sh24/contentful-service'
import styled from 'styled-components'
import { previewClient, contentDeliveryClient } from '../services/contentful'
import GenericPageTemplate from '../templates/generic-page-template.v2'

const slug = process.env.NOT_FOUND_ERROR_PAGE_SLUG

const BasicMessage = styled.div`
  padding-top: 200px;
  text-align: center;
`

const PageFallback = () => (
  <BasicMessage>Page not found.</BasicMessage>
)

const NotFoundPage = ({ page }) => (
  page ? <GenericPageTemplate page={page} /> : <PageFallback />
)

export async function getStaticProps({ preview }) {
  const client = preview ? previewClient : contentDeliveryClient
  const page = await getPage({ client, slug, pageTypes: ['page', 'standard'] })
  const siteConfig = await getSiteConfig({ client })
  const translations = await getTranslations({ client })

  return {
    props: {
      page,
      preview: !!preview,
      siteConfig,
      translations,
    },
    revalidate: parseInt(process.env.REVALIDATE_WINDOW, 10),
  }
}

export default NotFoundPage
